interface IArrayToObject {
  arr: any[];
  property: string | number;
  defaultValue?: string | null;
}

/**
 *
 * @param arr - the array
 * @param property - the property or index you would like to use
 * @param defaultValue - default value from object array
 * @description Transforms any array passed into a mapped object by the object property or index
 */
export function ArrayToObject({
  arr,
  property,
  defaultValue = null,
}: IArrayToObject): Record<string, any> {
  return arr.reduce((acc, current) => {
    return {
      ...acc,
      [current[property]]: defaultValue ? current[defaultValue] : null,
    };
  }, {});
}

interface IFlattenArrays {
  arrs: [any[]];
}

/**
 *
 * @param arrs - arrays nested together
 */

export function FlattenArrays({ arrs }: IFlattenArrays): any[] {
  return arrs.flat();
}

export function merge(left: Array<any>, right: Array<any>, property: string) {
  const sortedArr = []; // the sorted items will go here
  while (left.length && right.length) {
    // Insert the smallest item into sortedArr
    if (left[0][property] < right[0][property]) {
      sortedArr.push(left.shift());
    } else {
      sortedArr.push(right.shift());
    }
  }
  // Use spread operators to create a new array, combining the three arrays
  return [...sortedArr, ...left, ...right];
}

export function mergeSort(arr: Array<any>, property: string) {
  // Base case
  if (arr.length <= 1) return arr;
  const mid = Math.floor(arr.length / 2);
  // Recursive calls
  const left: Array<any> = mergeSort(arr.slice(0, mid), property);
  const right: Array<any> = mergeSort(arr.slice(mid), property);
  return merge(left, right, property);
}

export function formatDate(date: number | Date) {
  return new Intl.DateTimeFormat('en-ZA', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  })
    .format(date)
    .replace(',', '');
}

export function formatTransactionDate(date: Date) {
  return new Intl.DateTimeFormat('en-UK', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  })
    .format(new Date(date))
    .replaceAll(',', '');
}

export function formatMobileTransactionDate(recordDate) {
  const date = new Date(recordDate);
  const hh = ('0' + date.getHours()).slice(-2);
  const mm = ('0' + date.getMinutes()).slice(-2);
  const options: DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
  };
  return date.toLocaleDateString('en-GB', options) + `-${hh}:${mm}`;
}

export function chunk(arr: Array<any>, chunkSize: number) {
  const R = [];
  for (let i = 0; i < arr.length; i += chunkSize)
    R.push(arr.slice(i, i + chunkSize));
  return R;
}

export function splitCookieByKey(cookieString: string, key: string) {
  const value = `; ${cookieString}`;
  const parts = value.split(`; ${key}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
